
  import { Component, Vue } from 'vue-property-decorator';
  import { AuthenticationService } from '../../services/authentication.service';
  import ArticleEditor from '@/components/articles/ArticleEditor.component.vue';
  import * as Consts from '@/scripts/Consts';

/**
 * @desc page de création d'un article en tant que classe
 */
  @Component({
    components: {
      'article-editor' : ArticleEditor,
    },
  })
  export default class CreateArticleClassroom extends Vue {
    mounted(): void {
      if(AuthenticationService.getUserStatus() !== Consts.user_status_classroom){
        this.$router.push('/');
        return;
      }
    }
  }
